const fields = [
    { key: 'index', label: "No", _style:'min-width:50px'},
    { key: 'select', label: "select", _style:'min-width:50px' },
    { key: 'kecamatan', label: "Kecamatan", _style:'min-width:100px;' },
    { key: 'desa', label: "Desa", _style:'min-width:100px;' },
    { key: 'bulan', label: "Bulan", _style:'min-width:100px;' },
    { key: 'tahun', label: "Tahun", _style:'min-width:100px;' },
    { key: 'jenis_hewan', label: "Jenis Hewan", _style:'min-width:50px'},
    { key: 'jenis_kelamin', label: "Jenis Kelamin", _style:'min-width:100px;' },
    { key: 'umur', label: "Umur", _style:'min-width:100px;' },
    { key: 'tipe', label:"tipe", _style:'min-width:120px' },
    { key: 'jumlah', label: "Jumlah", _style:'min-width:100px' },
    { key: 'dibuat_oleh', label: "Diinput Oleh", _style:'min-width:100px' },
    { key: 'tanggal_input', label: "Tanggal Input", _style:'min-width:100px' },
    { key: 'tanggal_update',label: "Tanggal Update", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]


export { fields }